import React from "react"
import { Link } from "gatsby"
import BackgroundPane from "../components/backgroundPane"
import HomeHeading from "../components/homeHeading"
import Layout from "../components/layout"
import { Row, Col } from "antd"
import PostCard from "../components/postCard"
import HomeGrid from "../components/homeGrid"
import styled from "styled-components"
const FlexRow = styled.div`
  @media only screen and (min-width: 1440px) {
    display: flex;
    justify-content: center;
  }
`
const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const sorted = edges.sort((a, b) =>
    a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
  )
  return (
    <Layout>
      <FlexRow>
        <Col>
          <BackgroundPane>
            <Row type="flex" justify="center">
              <Col>
                <HomeHeading>Tripplo Help Docs</HomeHeading>
              </Col>
            </Row>
            <HomeGrid>
              {sorted.map(edge => (
                <div key={edge.node.id}>
                  <Link to={edge.node.frontmatter.path}>
                    <PostCard>{edge.node.frontmatter.title}</PostCard>
                  </Link>
                </div>
              ))}
            </HomeGrid>
          </BackgroundPane>
        </Col>
      </FlexRow>
    </Layout>
  )
}
export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            path
            title
            order
          }
        }
      }
    }
  }
`
export default IndexPage
