import styled from "styled-components"
import constants from "../constants"

const PostCard = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  background: ${constants.colours.white};
  border: none;
  width: 298px;
  height: 102px;
  border: 1px solid #dfe0eb;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(179, 189, 224, 0.5);
  border-radius: 4px;
  color: ${constants.colours.tripplo_blue};
  padding: 2rem;
`

export default PostCard
