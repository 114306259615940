import styled from "styled-components"

const BackgroundPane = styled.div`
  background: #ffffff;
  border-radius: 4px;
  border: red;
  width: 1000px;
  height: 678px;
  padding: 30px;
  margin-top: -80px;
  text-align: center;

  @media only screen and (max-width: 414px) {
    width: 350px;
    margin-top: -50px;
    margin-left: 30px;
  }
  @media (max-width: 360px) {
    width: 350px;
    margin-top: -50px;
    margin-left: 5px;
  }

  @media (max-width: 375px) {
    width: 350px;
    margin-top: -50px;
    margin-left: 10px;
  }

  // @media screen (max-width: 1366px) and (min-width: 1024px) {
  //   width: 950px;
  //   margin-left: 35px;
  // }
  // @media (min-width: 1366px) {
  //   margin-left: 180px;
  // }
  // @media (min-width: 1440px) {
  //   margin-left: 0;
  // }

  @media only screen and (min-width: 768px) {
    width: 700px;
    margin-top: -50px;
    margin-left: 50px;
  }

  @media screen and (min-width: 1024px) {
    width: 950px;
  }

  @media screen (max-width: 1440) and (min-width: 1280px) {
    width: 950px;
    margin-left: 150px;
  }
`

export default BackgroundPane
